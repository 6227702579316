#blogs{
    min-height: 75vh;
    padding: 75px 2rem 2rem 2rem;
    text-align: center;
}

#get-the{
    font-family: 'DesMontille';
    color: #B0F6FA;
    font-size: 109px;
    margin: 0;
    z-index: 4;
    position: relative;

}

#merch-txt{
    font-family: 'BigSans';
    color: #77FD78;
    font-size: 56px;
    margin: 0;
    position: relative;
    top: -30px;
    z-index: 3;

}

#merch-list{
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    /*justify-content: space-between;*/
}

/* MERCH CARD */

.merch-card{
    width: 30%;
    min-height: 500px ;
    background-color: #fff;
    margin: 1rem;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    text-align: left;
    color: #FB4622;
    font-family: 'BigSans';
    text-transform: uppercase;
    position: relative;
}

.size-container{
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid #FB4622;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 3px;
    font-family: 'GreenGroveBold', serif;
    cursor: pointer;
}

.size-container-selected{
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid #FB4622;
    background-color: #FB4622;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 3px;
    font-family: 'GreenGroveBold', serif;
    cursor: pointer;
}

.not-for-sale{
    height: 30px;
    width: 30px;
    border-radius: 15px;
    border: 1px solid rgb(164, 164, 164);
    color: rgb(164, 164, 164);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 3px;
    font-family: 'GreenGroveBold', serif;
}

.blog-link{
    text-transform: uppercase;
    font-family: 'Futura-PT', sans-serif;
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #FB4622;
    text-decoration: underline;
}

.merch-btn{
    border: 2px solid #FB4622;
    color: #FB4622;
    background-color: unset;
    font-family: 'GreenGroveBold', serif;
    padding: 7px 10px 5px 10px;
    font-size: 18px;
    max-width: 200px;
}

.disabled-merch-btn{
    border: unset;
    color: #FB4622;
    background-color: unset;
    font-family: 'GreenGroveBold', serif;
    padding: 0;
    font-size: 18px;
    z-index: 999;
    text-transform: uppercase;
}

.merch-card-image{
    width: 100%;
    height: 375px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.blog-category{
    padding: 10px 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffb5ff;
}

#merch-page{
    background: white;
    min-height: 70vh;
}

/* BLOG Page */

#blog-page{
    padding: 90px 2rem 2rem 2rem;
}

.merch-arrow{
    height: 40px;
    width: 40px;
    border-radius: 20px;
    position: absolute;
    top: 48%;
    padding: 10px;
    background-color: rgba(119, 253, 120, 0.6);
    margin: 5px;
}

.merch-arrow img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.product-container {
    display: flex;
    justify-content: space-evenly;
    padding: 100px 20px;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
}

.image-gallery {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.primary-img {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
}

.thumbnail-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.7;
}

.thumbnail.active {
    opacity: 1;
    border: 2px solid #000;
}

.product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}

.product-details h1{
    color: #FB4622;
    font-family: 'BigSans';
    font-weight: 400;
    margin: 0;
}


.product-description {
    font-size: 14px;
    color: #555;
}


@media only screen and (max-width: 1100px) {
    .merch-card{
        width: 46%;
    }
}

@media only screen and (max-width: 850px) {
   
    #read-the{
        font-size: 20vw;
    }
    
    #blog-txt{
        font-size: 10vw;
        top: -20px;
    }

    .merch-card{
        width: 100%;
    }

    #blogs{
        padding: 75px 0 2rem 0;
    }
    #blog-page{
        padding: 75px 1rem 1rem 1rem;
    }

    .merch-card-image{
        width: 100%;
        height: 325px;
        background-position: center;
        background-size: cover;
        position: relative;
    }
  }

  @media only screen and (max-width: 750px) {
    .product-container {
        flex-direction: column;
        padding: 70px 10px;
    }
    .product-details {
        padding: 10px 0;
    }
    
  }
